<template>
  <div class="page-card-demo page-info-content">
    <Tabs :active-name="formInline.state" :tabs-list="tabsList" @getTabName="getTabChange" />
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      reset
      :form-item-arr="searchFormItemArr"
    >
      <template>
        <el-form-item label="申请月份" label-width="90px">
          <el-date-picker
            v-model="applyDateInterval"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="(val) => getDate(val, 'apply')"
          />
        </el-form-item>
        <el-form-item label="开票月份" label-width="90px">
          <el-date-picker
            v-model="invoiceDateInterval"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="(val) => getDate(val, 'open')"
          />
        </el-form-item>
      </template>
    </FormSearch>
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      />
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import Table from '@/components/Table.vue'
import Tabs from '@/components/Tabs.vue'
import Pagination from '@/components/Pagination2.vue'
import {
  invoiceIssureQueryByPage
} from '@/api/shoppingMall'
export default {
  components: { FormSearch, Table, Pagination, Tabs },
  data() {
    return {
      tabsList: [
        { label: '全部发票', name: '' },
        { label: '开票中', name: '01' },
        { label: '已开票', name: '02' }
      ],
      applyDateInterval: [],
      invoiceDateInterval: [],
      formInline: {
        pageSize: 10,
        pageNum: 1,
        state: ''
      },
      listData: [],
      total: 0,
      loading: false,
      searchFormItemArr: [
        { type: 'select', label: '发票类型', value: 'invoiceType', width: '90px', pLabel: 'name', pValue: 'id', child: [{ id: '00', name: '增值税普通发票' }, { id: '01', name: '增值税专用发票' }] },
        { type: 'select', label: '发票介质', value: 'receiveType', width: '90px', pLabel: 'name', pValue: 'id', child: [{ id: '00', name: '电子' }, { id: '01', name: '纸质' }] },
        { type: 'input', label: '销售方', value: 'supplierCmpName', width: '90px' },
        { type: 'input', label: '购买方', value: 'fundsCmpName', width: '90px' }
      ],
      itemData: [
        { label: '申请日期', prop: 'createTime', width: 180 },
        { label: '开票日期', prop: 'openTime', width: 180 },
        { label: '状态', prop: 'state', child: [{ id: '01', name: '开票中' }, { id: '02', name: '已开票' }], width: 180 },
        { label: '发票代码', prop: 'invoiceCode', width: 180 },
        { label: '发票类型', prop: 'invoiceType', child: [{ id: '00', name: '增值税普通发票' }, { id: '01', name: '增值税专用发票' }], width: 180 },
        { label: '发票介质', prop: 'receiveType', child: [{ id: '00', name: '电子' }, { id: '01', name: '纸质' }], width: 180 },
        { label: '发票金额（元）', prop: 'invoiceAmt', width: 180 },
        { label: '购买方名称', prop: 'recvCmpName', width: 180 },
        { label: '销售方名称', prop: 'openCmpName', width: 180 }
      ],
      operationButton: [
        { bType: 'primary', label: '查看', handleEvent: this.detailsRow }
      ]
    }
  },
  methods: {
    // 获取日期
    getDate(value, type) {
      if (type === 'apply') {
        if (!value) {
          this.formInline.applyTimeBegin = ''
          this.formInline.applyTimeEnd = ''
          return
        }
        this.formInline.applyTimeBegin = value[0]
        this.formInline.applyTimeEnd = value[1]
      }
      if (type === 'open') {
        if (!value) {
          this.formInline.openTimeBegin = ''
          this.formInline.openTimeEnd = ''
          return
        }
        this.formInline.openTimeBegin = value[0]
        this.formInline.openTimeEnd = value[1]
      }
    },
    // 获取分页数据
    getdata(type) {
      if (type === 'reset') {
        this.applyDateInterval = []
        this.invoiceDateInterval = []
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      invoiceIssureQueryByPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    },
    // 获取当前tab
    getTabChange(val) {
      if (!val || val === '0') { this.formInline.state = '' } else { this.formInline.state = val }
      this.getdata()
    },
    // 查看
    detailsRow({ id, busiId }) {
      this.$router.push({
        path: '/shoppingMall/invoiceDetails',
        query: { id, busiId }
      })
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.el-tabs__nav-wrap::after) {
  width: 0;
}
:deep(.el-tabs__active-bar) {
  display: none;
}
</style>
